import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL, BACKEND_BASE_URL} from "@/shared/consts";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { ClatTaxPayload, ProductsState } from "./types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";
import Utils from "@/modules/shared/utils/utils";

export const state: ProductsState = {
    productsTableIsBusy: false,
    scheduleTableIsBusy: false,
    clatTaxTableIsBusy: false,
    productsTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10),
    },
    scheduleTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10)
    },
    clatTaxTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10)
    },
    dictionaries: null,
    attachmentsUploadProgress: {
        progress: -1,
    },
}

export const getters: GetterTree<ProductsState, RootState> = {
    getProductsTableItems(state) {
        return JSON.parse(JSON.stringify(state.productsTable.items));
    },
    getProductsTableQuery(state) {
        return JSON.parse(JSON.stringify(state.productsTable.query));
    },
    getProductsTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.productsTable.perPage));
    },
    getProductsTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.productsTable.query.filters));
    },
    getScheduleTableItems(state) {
        return JSON.parse(JSON.stringify(state.scheduleTable.items));
    },
    getScheduleTableQuery(state) {
        return JSON.parse(JSON.stringify(state.scheduleTable.query));
    },
    getScheduleTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.scheduleTable.perPage));
    },
    getScheduleTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.scheduleTable.query.filters));
    },
    getDictionary(state) {
        return JSON.parse(JSON.stringify(state.dictionaries));
    },
    getClatTaxTableItems(state) {
        return JSON.parse(JSON.stringify(state.clatTaxTable.items));
    },
    getClatTaxTableQuery(state) {
        return JSON.parse(JSON.stringify(state.clatTaxTable.query));
    },
    getClatTaxTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.clatTaxTable.perPage));
    },
    getClatTaxTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.clatTaxTable.query.filters));
    },
    getClatTaxDictionary(state) {
        return JSON.parse(JSON.stringify(state.dictionaries));
    },
}

export const mutations: MutationTree<ProductsState> = {
    setProductsTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
        state.productsTable = { ...state.productsTable, items: payload.items, totalCount: payload.totalCount};
    },
    setProductsTableQuery(state, payload: TableQuery): void {
        state.productsTable.query = payload;
    },
    setProductsTableBusy(state,payload: boolean): void {
        state.productsTableIsBusy = payload;
    },
    setProductsTablePerPage(state, payload: number): void {
        state.productsTable.perPage = payload;
    },
    setProductsTableSortingQuery(state, payload: Sorting): void {
        state.productsTable.query.sorting = payload;
        state.productsTable.query.offset = 0;
    },
    setProductsTableFiltersQuery(state, payload: string) {
        state.productsTable.query.filters = payload;
    },
    setScheduleTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
        state.scheduleTable = { ...state.scheduleTable, items: payload.items, totalCount: payload.totalCount};
    },
    setScheduleTableQuery(state, payload: TableQuery): void {
        state.scheduleTable.query = payload;
    },
    setScheduleTableBusy(state,payload: boolean): void {
        state.scheduleTableIsBusy = payload;
    },
    setScheduleTablePerPage(state, payload: number): void {
        state.scheduleTable.perPage = payload;
    },
    setScheduleTableSortingQuery(state, payload: Sorting): void {
        state.scheduleTable.query.sorting = payload;
        state.scheduleTable.query.offset = 0;
    },
    setScheduleTableFiltersQuery(state, payload: string) {
        state.scheduleTable.query.filters = payload;
    },
    setClatTaxTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
        state.clatTaxTable = { ...state.clatTaxTable, items: payload.items, totalCount: payload.totalCount};
    },
    setClatTaxTableQuery(state, payload: TableQuery): void {
        state.clatTaxTable.query = payload;
    },
    setClatTaxTableBusy(state,payload: boolean): void {
        state.clatTaxTableIsBusy = payload;
    },
    setClatTaxTablePerPage(state, payload: number): void {
        state.clatTaxTable.perPage = payload;
    },
    setClatTaxTableSortingQuery(state, payload: Sorting): void {
        state.clatTaxTable.query.sorting = payload;
        state.clatTaxTable.query.offset = 0;
    },
    setClatTaxTableFiltersQuery(state, payload: string) {
        state.clatTaxTable.query.filters = payload;
    },
    setDictionaries(state, payload: any) {
        state.dictionaries = payload;
    },
    setAttachmentsUploadProgress(state, payload: number): void {
        state.attachmentsUploadProgress = {
            progress: payload
        };
    },
}

export const actions: ActionTree<ProductsState, RootState> = {
    async getProducts({state,commit}, filtersQuery?: string): Promise<Array<any>> {
        const queryString = state.productsTable.query.getStringQuery();
        filtersQuery = filtersQuery ? filtersQuery : '';
        const queryData = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/products${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

        const payload: {items: Array<any>, totalCount: string} = { items: queryData.data, totalCount: queryData.headers['x-total-count'] }
        commit('setProductsTableItems', {
            items: payload.items,
            totalCount: payload.totalCount,
        });

        return payload.items;
    },
    async savePaymentInformation({state}, payload: { productId: string, data: { paymentValue: number, paymentDate: string } }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/payment-data`, payload.data);

        return data;
    },
    async savePayment({state}, payload: { productId: string, data: { paymentValue: number, paymentDate: string } }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/payment-data`, payload.data);

        return data;
    },
    async deletePayment({state}, payload: { productId: string, paymentId: string }) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/payment-data/${payload.paymentId}`);

        return data;
    },
    async getRepaymentSchedule({state}, productId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/repayment-schedule`);

        return data;
    },
    async generateRepaymentSchedule({state}, productId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/repayment-schedule/generate`);

        return data;
    },
    async saveRepaymentSchedule({state}, payload: { productId: string, data: any[] }) {
        const { data } = await axios.put(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/repayment-schedule`, { items: payload.data });

        return data;
    },
    async getScheduleList({state, commit}, filtersQuery?: string) {
        const queryString = state.scheduleTable.query.getStringQuery();
        filtersQuery = filtersQuery ? filtersQuery : '';

        const queryData = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/repayment-schedule${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

        const payload: {items: Array<any>, totalCount: string} = { items: queryData.data, totalCount: queryData.headers['x-total-count'] }
        commit('setScheduleTableItems', {
            items: payload.items,
            totalCount: payload.totalCount,
        });

        return payload.items;
    },
    async getDictionaries({state, commit}) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/product/repayment-schedule/dictionaries`);

        commit('setDictionaries', data);

        return data;
    },
    async updatePaymentStatuses({state}, payload: { items: { productId: string, paymentId: string }[], status: string }) {
        const promises: Array<any> = [];
        
        payload.items.forEach((item: any) => {
            promises.push(axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${item.productId}/repayment-schedule/${item.paymentId}/payment-status`, { paymentStatus: payload.status }));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async updatePaymentStatusesByFilters({state}, payload: { filtersQuery: string, status: string }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/repayment-schedule/payment-status?${payload?.filtersQuery || ''}`, {
            paymentStatus: payload?.status,
        })

        return data;
    },
    async updatePaymentDescriptions({state}, payload: { items: { productId: string, paymentId: string }[], description: string }) {
        const promises: Array<any> = [];
        
        payload.items.forEach((item: any) => {
            promises.push(axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${item.productId}/repayment-schedule/${item.paymentId}/description`, { description: payload.description }));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async updatePaymentBookDates({state}, payload: { items: { productId: string, paymentId: string }[], paymentBookDate: string }) {
        const promises: Array<any> = [];

        payload.items.forEach((item: any) => {
            promises.push(axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${item.productId}/repayment-schedule/${item.paymentId}/payment-book-date`, { paymentBookDate: payload.paymentBookDate }));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async updatePaymentBookDatesByFilters({state}, payload: { filtersQuery: string, paymentBookDate: string }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/repayment-schedule/payment-book-date?${payload?.filtersQuery || ''}`, {
            paymentBookDate: payload?.paymentBookDate
        })

        return data
    },
    async updateDescriptions({state}, payload: { items: { productId: string, paymentId: string }[], description: string }) {
        const promises: Array<any> = [];

        payload.items.forEach((item: any) => {
            promises.push(axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${item.productId}/repayment-schedule/${item.paymentId}/description`, { description: payload.description }));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async updateDescriptionsByFilters({state}, payload: { filtersQuery: string, description: string }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/repayment-schedule/description?${payload?.filtersQuery || ''}`, {
            description: payload.description
        })

        return data
    },
    async updatePaymentData({state}, payload: { productId: string, paymentId: string, data: { paymentStatus: string, paymentMethod: string, paymentBookDate: string, description: string | null } }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/repayment-schedule/${payload.paymentId}`, payload.data);

        return data;
    },
    async updatePaymentDataByFilters({state}, payload: { filtersQuery: string, data: { paymentStatus: string, paymentMethod: string, paymentBookDate: string, description: string | null } }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/repayment-schedule?${payload.filtersQuery || ''}`, payload.data);

        return data;
    },
    async generatePaymentPackage({state}, payload: { name: string, paymentDate: string, isSepa: boolean, items: { productId: string, paymentIds: string[] }[] }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/generate-payment-package`, payload);

        if (data) {
            Utils.downloadZippedFilesByUrls(data.map((el: any, index: number) => { 
                const extension: string = el?.fileExtension ? el.fileExtension : 'xml'
                return { fileName: `${el.name}-${index}.${extension}`, path: el.filePath } 
            }), payload.name)
        }
    },
    // items are for productIds
    async generateClatTaxPaymentPackage({state}, payload: { name: string, paymentDate: string, taxOfficeCode: string, bankAccount: string, bankAccountType: string, periodType: string, periodDate: string, items: string[] }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/generate-clat-tax-package`, payload);

        if (data) {
            const extension: string = data?.fileExtension ? data.fileExtension : 'xml'
            Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${data.filePath}`, `${data.name}.${extension}`);
        }

        return data
    },
    async exportCsvByIds({state}, payload: { ids: string[] }) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/repayment-schedule/export-by-ids/csv`, payload);

        return data;
    },
    async exportCsvByFilters({state}, filtersQuery: string) {
        const { data } = await axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/repayment-schedule/export-by-filters/csv?${filtersQuery}`);

        return data;
    },
    async closeProduct({state}, productId: string) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/close`);

        return data;
    },
    async getClatTaxList({state, commit}, filtersQuery?: string) {
        const queryString = state.clatTaxTable.query.getStringQuery();
        filtersQuery = filtersQuery ? filtersQuery : '';

        const queryData = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/clat-tax${queryString}${filtersQuery}`, { headers: { 'x-total-count': true} });

        const payload: {items: Array<any>, totalCount: string} = { items: queryData.data, totalCount: queryData.headers['x-total-count'] }
        commit('setClatTaxTableItems', {
            items: payload.items,
            totalCount: payload.totalCount,
        });

        return payload.items;
    },
    async getClatTax({state}, productId: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/clat-tax`);

        return data;
    },
    async saveClatTax({state}, payload: { productId: string, data: ClatTaxPayload }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/clat-tax`, payload.data);

        return data;
    },
    async deleteClatTax({state}, productId: string) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/clat-tax`);

        return data;
    },
    async saveClatTaxPaymentDate({state}, payload: { productId: string, paymentDate: string }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/clat-tax/payment-date`, payload.paymentDate);

        return data;
    },
    async saveClatTaxPaymentStatus({state}, payload: { productId: string, paymentStatus: string }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/clat-tax/payment-status`, payload.paymentStatus);

        return data;
    },
    async updateClatTaxPaymentData({state}, payload: { productId: string, data: { paymentStatus: string, description: string | null, paymentDate: string } }) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/clat-tax/payment-data`, payload.data);

        return data;
    },
    async updateClatTaxPaymentStatuses({state}, payload: { productIds: string[], status: string }) {
        const promises: Array<any> = [];
        
        payload.productIds.forEach((id: string) => {
            promises.push(axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${id}/clat-tax/payment-status`, { paymentStatus: payload.status }));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async updateClatTaxPaymentDates({state}, payload: { productIds: string[], paymentDate: string }) {
        const promises: Array<any> = [];

        payload.productIds.forEach((id: string) => {
            promises.push(axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${id}/clat-tax/payment-date`, { paymentDate: payload.paymentDate }));
        })

        const response = await Promise.all(promises);

        return response;
    },
    async generatePaymentPackageByFilters({state}, payload: { name: string, paymentDate: string, isSepa: boolean, items: any[], filters: string | null }) {
        const queryString = payload.filters ? `?${payload.filters}` : '';
        delete payload.filters;

        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/generate-payment-package-by-filters${queryString}`, payload);

        if (data) {
            Utils.downloadZippedFilesByUrls(data.map((el: any, index: number) => {
                const extension: string = el?.fileExtension ? el.fileExtension : 'xml'
                return { fileName: `${el.name}-${index}.${extension}`, path: el.filePath }
            }), payload.name)
        }

        return data;
    },
    async generateClatTaxPaymentPackageByFilters({state}, payload: { name: string, paymentDate: string, taxOfficeCode: string, bankAccount: string, bankAccountType: string, periodType: string, periodDate: string, items: any[], filters: string | null }) {
        const queryString = payload.filters ? `?${payload.filters}` : '';
        delete payload.filters;

        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/generate-clat-tax-package-by-filters${queryString}`, payload);

        if (data) {
            const extension: string = data?.fileExtension ? data.fileExtension : 'xml'
            Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${data.filePath}`, `${data.name}.${extension}`);
        }

        return data;
    },
    async declineUpdateInvestor({state, commit}, productId: string) {
        const { data } = await axios.patch(`${BACKEND_API_URL}/marketplace/legal-entity/product/${productId}/decline-update-investor`)

        return data
    },
    async uploadAttachments({state,commit}, payload: { files: any, productId: string }): Promise<any> {
        // eslint-disable-next-line prefer-const
        let requests: Array<Promise<any>> = [];

        payload.files.files.forEach((file: File) => {
            const formData = new FormData();
            formData.append('file', file);

            requests[file.name as any] = axios.post(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/attachment`, formData);
        });

        const results = await allProgress(requests, (p: any) => {
            commit('setAttachmentsUploadProgress', p);
        });

        return results;
    },
    async deleteAttachment({state, commit}, payload: { productId: string, attachmentId: string}) {
        const { data } = await axios.delete(`${BACKEND_API_URL}/marketplace/legal-entity/product/${payload.productId}/attachment/${payload.attachmentId}`)

        return data
    }
}

async function allProgress(proms: Array<any>, progress_cb: Function) {
    const results: Array<any> = [];
    let d = 0;
    progress_cb(0);
    Object.values(proms).forEach(p => {
        p.then(()=> {
            d++;
            progress_cb( (d * 100) / Object.keys(proms).length );
        });
    });
    let i = 0;
    await Promise.allSettled(Object.values(proms)).then((resp) => resp.forEach(result => {
        const key: any = Object.keys(proms)[i];
        results[key] = { status: result.status, data: result.status == 'rejected' ? result.reason : result.value };
        i++;
    }));

    return results;
}

export const products: Module<ProductsState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
